@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    opacity: 0;
  }

  to {
    opacity: 1;
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    opacity: 0;
    -webkit-transform: rotate(0deg);
  }

  to {
    opacity: 1;
    -webkit-transform: rotate(360deg);
  }
}

@keyframes fadeInOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.col-fs-c {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.col-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.col-c-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.row-c-c {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-fs-c {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}


.row-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.row-sb-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-fe-c {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.desktop-only {
  display: block;
}

@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }
}

.mobile-only {
  display: none;
}

@media (max-width: 767px) {
  .mobile-only {
    display: block;
  }
}

.layout__master {
  width: 100vw;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
}

.layout__master div.mobile-bottom-nav {
  border-top: 1px solid rgba(2, 17, 69, 0.1);
  box-shadow: 0 0 10px 0 rgba(2, 17, 69, 0.05);
  position: fixed;
  height: 64px;
  bottom: 0;
  z-index: 10;
}

.layout__master div.page-cont {
  margin-left: 308px;
  width: 100%;
  margin-right: 16px;
  position: relative;
  margin-bottom: 24px;
  z-index: 1;
}

@media (max-width: 767px) {
  .layout__master div.page-cont {
    width: 100vw;
    min-height: calc(100vh - 16px - 64px);
    margin-bottom: 16px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: calc(64px + 16px);
  }
}

.layout__master div.sb {
  position: fixed;
}

.layout__master div.sbr {
  display: none;
}

.layout__master .page-cont.embed {
  width: 100vw;
  margin-left: 0;
}

.sb {
  width: 288px;
  height: 100vh;
  padding-top: 24px;
  padding-bottom: 36px;
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  font-size: 14px;
  color: #203b54;
  font-weight: 500;
  z-index: 999;
}

.sb .sb--scrollArea {
  overflow: auto;
  width: 100%;
  height: 100%;
  padding-bottom: 32px;
}

.sb .sb--section {
  width: 100%;
}

.sb .sb--section .section_inner {
  border-radius: 12px;
  background-color: #ebf3fb;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
}

.sb .sb--sectionLabel {
  width: calc(100% - 32px);
  height: 28px;
  line-height: 28px;
  font-weight: 700;
  font-size: 14px;
  color: #6487a6;
  text-transform: capitalize;
  margin-bottom: 4px;
  margin-top: 12px;
  margin-left: 16px;
  margin-right: 16px;
}

.sb .sb--sectionLabel svg {
  margin: 0 !important;
  color: #97b6ce;
}

.sb .sb--sectionLabel svg:hover {
  color: #6487a6;
}

.sb .sb--showMore {
  transition: all 200ms;
  height: 28px;
  width: 100%;
  border-top: 1px solid rgba(32, 59, 84, 0.05);
  background-color: rgba(32, 59, 84, 0.03);
  color: #97b6ce;
  margin-bottom: -12px;
  margin-top: 8px;
  padding-left: 44px;
  cursor: pointer;
}

.sb .sb--showMore > img {
  transition: all 200ms;
  transform: rotate(0);
  margin-left: 8px;
}

.sb .sb--showMore > img.flip {
  transform: rotate(180deg);
}

.sb .sb--showMore:hover {
  background-color: rgba(32, 59, 84, 0.05);
}

.sb .sb--showMore:active {
  background-color: rgba(32, 59, 84, 0.1);
}

.sb .sb-item-container {
  transition: all 200ms;
  width: 100%;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.sb .sb-item-container.showing {
  height: 32px;
  opacity: 1;
}

.sb .sb--item {
  transition: all 200ms;
  border-radius: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding-left: 16px;
  padding-right: 16px;
  height: 100%;
  white-space: nowrap;
  width: 100%;
}

.sb .sb--item span {
  transition: all 200ms;
  opacity: 0.75;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  box-shadow: 0 2px 0 0 transparent;
}

.sb .sb--item > div.icon {
  border-radius: 1000px;
  height: 20px;
  width: 20px;
  margin-right: 8px;
  font-size: 16px;
}

.sb .sb--item.active span {
  box-shadow: 0 2px 0 0 #1782fc;
  font-weight: 700;
  color: #1782fc;
}

.sb .sb--item:hover .sb--settings {
  opacity: 1;
}

.sb .sb--item:hover:not(.active) {
  cursor: pointer;
}

.sb .sb--item:hover:not(.active) span {
  box-shadow: 0 2px 0 0 rgba(32, 59, 84, 0.75);
  font-weight: 700;
  opacity: 0.75;
}

.sb .sb--settings {
  opacity: 0;
  color: #97b6ce;
}

.sb .sb--settings:hover {
  cursor: pointer;
  color: #6487a6;
}

.sb .sb--chatUnreads {
  transition: all 200ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 1000px;
  width: 10px;
  height: 10px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 700;
  color: white;
  background-color: #1782fc;
}

.sb .sb--logo {
  margin-bottom: 20px;
  height: 36px;
  width: 100%;
  overflow: hidden;
}

.sb .sb--btn {
  transition: all 200ms;
  border-radius: 1000px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  margin-bottom: 20px;
  height: 36px;
  width: 100%;
  background-color: #1782fc;
  color: white;
  font-weight: 600;
}

.pc-container {
  width: 100%;
}

@media (max-width: 767px) {
  .pc-container {
    margin-top: 28px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.pc-composerPlaceholder {
  border-radius: 12px;
  transition: all 200ms;
  width: 100%;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  background-color: #ebf3fb;
  color: #97b6ce;
}

.pc-composerPlaceholder:hover {
  cursor: pointer;
  background-color: #e7f2fa;
}

.pc-composerPlaceholder:active {
  opacity: 0.85;
}

.pc {
  border-radius: 12px;
  transition: all 200ms;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 28px;
  padding-bottom: 28px;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(100, 135, 166, 0.07), 0px 5px 15px rgba(100, 135, 166, 0.1);
  transform: scale(1);
}

.pc:not(.onPostPage):hover {
  cursor: pointer;
  transform: scale(1.003);
  box-shadow: 0px 2px 4px rgba(100, 135, 166, 0.1), 0px 5px 20px rgba(100, 135, 166, 0.15);
}

.pc:not(.onPostPage):hover .pc--name:hover {
  text-decoration: underline;
}

.pc.onPostPage .left:hover {
  cursor: pointer;
}

.pc.onPostPage .left:hover .pc--name {
  text-decoration: underline;
}

.pc .pc--comments,
  .pc .pc--pinBtn {
  transition: all 200ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 1000px;
  padding-left: 12px;
  padding-right: 12px;
  height: 28px;
  font-size: 14px;
  font-weight: 600;
  color: #6487a6;
  background-color: #e7f2fa;
}

.pc .pc--comments > svg,
    .pc .pc--pinBtn > svg {
  margin-left: 8px;
  height: 12px;
  fill: currentColor;
  width: 12px;
}

.pc .pc--comments.pinned,
    .pc .pc--pinBtn.pinned {
  color: white;
  background-color: #3a5b79;
}

.pc .pc--pinBtn {
  cursor: pointer;
}

.pc .pc--pinIndicator {
  transition: all 200ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 1000px;
  font-size: 14px;
  font-weight: 500;
  color: #3a5b79;
  background-color: #e7f2fa;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.pc .pc--pinIndicator span {
  margin-left: 8px;
}

.pc .pc--title > span {
  font-size: 26px;
  line-height: 24px;
  font-weight: 700;
  color: #203b54;
}

.pc .pc--content {
  margin-top: 12px;
  margin-bottom: 28px;
  line-height: 24px;
  font-size: 16px;
  color: #13253e;
}

.pc .pc--bottom {
  font-size: 14px;
  font-weight: 600;
  color: #97b6ce;
}

@media (max-width: 767px) {
  .pc .pc--bottom {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .pc .pc--bottom > div {
    width: 100%;
  }

  .pc .pc--bottom > div.right {
    justify-content: space-between;
    margin-top: 8px;
  }
}

.pc .pc--userPhoto {
  border-radius: 1000px;
  height: 28px;
  width: 28px;
  margin-right: 8px;
}

.pc .pc--name {
  transition: all 200ms;
  color: #3a5b79;
}

.pc .pc--time {
  font-size: 12px;
  font-weight: 400;
  margin-top: -4px;
}

.pc .pc--views {
  font-weight: 400;
  margin-right: 8px;
}

.pc .pc--likes,
  .pc .pc--comments {
  margin-left: 8px;
}

.voter {
  transition: all 200ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 1000px;
  height: 28px;
  padding-left: 12px;
  padding-right: 12px;
  color: #6487a6;
  background-color: #e7f2fa;
}

.voter > svg {
  margin-left: 8px;
  height: 12px;
  fill: currentColor;
  width: 12px;
}

.voter:hover {
  cursor: pointer;
  opacity: 0.75;
}

.voter.voted {
  color: white;
  background-color: #1782fc;
}

.commentEditor {
  width: 100%;
  margin-top: 16px;
}

.commentEditor .commentEditor-editor {
  border-radius: 4px;
  transition: all 200ms;
  background-color: #edf2f7;
  margin-bottom: 12px;
}

.commentEditor .commentEditor-footer {
  width: 100%;
  padding-bottom: 12px;
}

.commentEditor .footer-btn {
  transition: all 200ms;
  border-radius: 4px;
  background-color: #021145;
}

.commentEditor .footer-btn:hover {
  cursor: pointer;
  opacity: 0.85;
}

.commentEditorContainer {
  width: 100%;
}

.com {
  display: grid;
  grid-template-columns: 28px 1fr;
  grid-column-gap: 8px;
  margin-top: 28px;
  /*
  &:not(.hasChildren) {
    .com--border {
      display: none;
    }
  }*/
}

.com .com--top {
  font-size: 14px;
  font-weight: 600;
  color: #97b6ce;
}

.com .com--name,
  .com .com--time {
  margin: 0;
  padding: 0;
}

.com .com--name {
  transition: all 200ms;
  color: #3a5b79;
}

.com .com--time {
  font-size: 12px;
  font-weight: 400;
  margin-top: -4px;
}

.com .com--userPhoto,
  .com .com--border {
  border-radius: 1000px;
}

.com .com--userPhoto {
  height: 28px;
  width: 28px;
}

.com .com--border {
  width: 1px;
  height: 100%;
  margin-top: 12px;
  background-color: #d3e3ed;
}

.com .com--text {
  line-height: 24px;
  font-size: 16px;
  color: #3a5b79;
  margin-top: 4px;
  margin-bottom: 8px;
}

.com .com--reply {
  transition: all 200ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 1000px;
  padding-left: 12px;
  padding-right: 12px;
  height: 28px;
  font-size: 14px;
  font-weight: 600;
  color: #6487a6;
  background-color: #e7f2fa;
}

.com .com--reply > svg {
  margin-left: 8px;
  height: 12px;
  fill: currentColor;
  width: 12px;
}

.com .com--reply:hover {
  opacity: 0.75;
  cursor: pointer;
}

.commentContainer {
  margin-bottom: 1em;
}

.commentChild {
  transition: all 200ms;
  border-radius: 4px;
}

.commentEditor-editor.light {
  background-color: white;
}

.commentMargin {
  width: 100%;
  position: relative;
}

.commentFooter {
  flex-wrap: wrap;
}

.mobileBottom-unreadBubble {
  background-color: #17aafd;
  width: 10px;
  height: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
  border-radius: 50px;
}

.common-editButton, .common-backButton {
  border-radius: 1000px;
  transition: all 200ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: 600;
  height: 36px;
  color: #3a5b79;
}

.common-editButton:hover, .common-backButton:hover {
  cursor: pointer;
  opacity: .75;
}

.common-editButton:active, .common-backButton:active {
  opacity: .5;
}

.common-backButton > span > span {
  font-family: 'Open Sans', --apple-system;
}

.common-editButton {
  border: 2px solid #3a5b79;
  padding-left: 12px;
  padding-right: 12px;
}

.commonLabel {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(2, 17, 69, 0.75);
  line-height: 16px;
}

.commonLabel.includeMarginTop {
  margin-top: 28px;
}

.commonLabel > span {
  font-weight: 400;
  color: rgba(2, 17, 69, 0.5);
}

.commonLabel.large {
  font-size: 16px;
}

.postEditor-titleInput {
  transition: all 200ms;
  background-color: #f7f9ff;
  color: #021145;
  border: 1px solid rgba(2, 17, 69, 0.05);
}

.postEditor-titleInput::-moz-placeholder {
  color: rgba(2, 17, 69, 0.25);
}

.postEditor-titleInput:-ms-input-placeholder {
  color: rgba(2, 17, 69, 0.25);
}

.postEditor-titleInput::-ms-input-placeholder {
  color: rgba(2, 17, 69, 0.25);
}

.postEditor-titleInput::placeholder {
  color: rgba(2, 17, 69, 0.25);
}

.postEditor-titleInput:focus {
  background-color: transparent;
  border: 1px solid rgba(2, 17, 69, 0.25);
}

.postEditor .postEditor-footer {
  width: 100%;
  margin-top: 28px;
}

.postEditor .postEditor-footer .postEditor-footer-right {
  flex: 1;
}

.postEditor .postEditor-footer .postEditor-footer-right .savebtn {
  background-color: #021145;
  color: white;
}

.backLink {
  transition: all 200ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-weight: 500;
  color: rgba(2, 17, 69, 0.5);
}

.backLink:hover {
  cursor: pointer;
  color: #021145;
}

.backLink:active {
  opacity: .85;
}

.spinner {
  transition: all 400ms;
  border-radius: 100px;
  width: 36px;
  height: 36px;
  border: 2px solid rgba(2, 17, 69, 0.75);
  background-color: transparent !important;
  border-top-color: rgba(2, 17, 69, 0.25);
  border-left-color: rgba(2, 17, 69, 0.25);
  animation: spin 1s ease-in-out infinite, fadeInOpacity 500ms, appear 500ms;
  -webkit-animation: spin 1s ease-in-out infinite, fadeInOpacity 500ms, appear 500ms;
  -moz-animation: spin 1s ease-in-out infinite, fadeInOpacity 500ms, appear 500ms;
}

.chatBox {
  height: 100%;
  max-width: calc(1080px - 298px);
  position: relative;
  margin-bottom: 80px;
}

.chatBox .chatBox-title {
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
  height: 80px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #f9fcff;
  z-index: 3;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .chatBox .chatBox-title {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .chatBox .chatBox-title {
    border-bottom: 1px solid #e7f2fa;
    margin-top: 0;
  }
}

.chatBox .chatBox-title__bg {
  height: 100%;
  margin-left: 298px;
  width: 100%;
  left: 0;
  top: 0;
  position: relative;
  background-color: #f9fcff;
  z-index: 19;
  pointer-events: none;
}

@media (max-width: 767px) {
  .chatBox .chatBox-title__bg {
    margin-left: 0;
    max-width: none;
  }
}

.chatBox .chatBox-title__in {
  width: 100%;
  position: absolute;
  z-index: 20;
  background-color: #f9fcff;
}

.chatBox .chatBox-title__in__in {
  border-bottom: 1px solid #e7f2fa;
  margin-bottom: 0;
  max-width: calc(1080px - 298px);
  margin-left: 298px;
  width: 100%;
  position: relative;
}

@media (max-width: 767px) {
  .chatBox .chatBox-title__in__in {
    border-bottom: none;
    justify-content: space-between;
    margin-left: 0;
    max-width: none;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.chatBox .chatBox-title h1 {
  font-size: 28px;
  font-weight: 700;
  margin-right: 28px;
  color: #203b54;
}

@media (max-width: 767px) {
  .chatBox .chatBox-title h1 {
    margin-right: 0;
  }
}

.chatBox .chatBox-chatMessages {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 80px;
  padding-top: 36px;
  overflow-y: auto;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer {
  position: relative;
  align-items: flex-end;
}

@media (max-width: 767px) {
  .chatBox .chatBox-chatMessages .chatBox-messageContainer {
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px);
  }
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer.sentFromMe {
  flex-direction: row-reverse;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer.sentFromMe .chatBox-message {
  max-width: 640px;
  align-items: flex-end;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer.sentFromMe .chatBox-message:not(.userPhotoShowing) {
  margin-left: 0;
  margin-right: 52px;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer.sentFromMe .chatBox-message.userPhotoShowing {
  margin-right: 12px;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer.sentFromMe .chatBox-message.userPhotoShowing .time-status {
  flex-direction: row-reverse;
  margin-left: 0;
  margin-right: 16px;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer.sentFromMe .chatBox-message > div.chatBox-multipleSend {
  margin-left: 0;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer.sentFromMe .chatBox-message > span {
  margin-left: auto;
  margin-right: 40px;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer.sentFromMe .chatBox-message .chatBox-messageContent {
  background-color: #1782fc;
  color: white;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer.sentFromMe .chatBox-message .chatBox-messageContentAndDelete {
  flex-direction: row-reverse;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer.sentFromMe .chatBox-message .chatBox-messageContentAndDelete .chat-delete-btn {
  margin-left: 0;
  margin-right: 12px;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-userPhoto {
  border: 1px solid rgba(2, 17, 69, 0.1);
  margin-bottom: 28px;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-userPhoto * {
  position: relative;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-message {
  flex: 1;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-message:not(.userPhotoShowing) {
  margin-left: 52px;
  margin-bottom: 6px;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-message.userPhotoShowing {
  margin-left: 12px;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-message:hover .chat-delete-btn {
  opacity: 1;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-message > div {
  flex: 1;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-message .chat-delete-btn {
  transition: all 200ms;
  border-radius: 1000px;
  opacity: 0;
  width: 36px;
  height: 36px;
  background-color: #e7f2fa;
  margin-left: 12px;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-message .chat-delete-btn > * {
  color: #3a5b79;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-message .chat-delete-btn:hover {
  cursor: pointer;
  background-color: #d3e3ed;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-message .time-status {
  font-size: 14px;
  color: rgba(2, 17, 69, 0.5);
  margin-left: 16px;
  height: 28px;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-message .chatBox-messageName {
  color: #6487a6;
  margin-bottom: 4px;
}

.chatBox .chatBox-chatMessages .chatBox-messageContainer .chatBox-message .chatBox-messageContent {
  border-radius: 24px;
  flex: 1;
  overflow-wrap: anywhere;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  font-weight: 500;
  background-color: #e7f2fa;
  border: 1px solid #e0e4ec;
  line-height: 20px;
  color: #3a5b79;
}

.chatBox .chatBox-composerContainer {
  height: 80px;
  position: fixed;
  padding-right: 16px;
  width: calc(100% - 16px);
  bottom: 0;
  max-width: calc(1080px - 298px);
  background-color: #f9fcff;
}

@media (max-width: 767px) {
  .chatBox .chatBox-composerContainer {
    border-top: 1px solid #e7f2fa;
    bottom: 64px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.chatBox .chatBox-composerContainer .chatBox-attachedImage {
  position: absolute;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin-top: -100px;
  background: white;
  padding: 5px;
  border: 1px solid #0000001f;
}

.chatBox .chatBox-composerContainer .chatBox-attachedImage .deleteCircle {
  position: absolute;
  top: -12px;
  right: -12px;
  background: white;
  border-radius: 200px;
}

.chatBox .chatBox-composerContainer .chatBox-composer {
  flex: 1;
  margin-right: 16px;
}

@media (max-width: 767px) {
  .chatBox .chatBox-composerContainer .chatBox-composer {
    width: calc(100% - 64px);
  }
}

.chatBox .chatBox-composerContainer .chatBox-composer > div {
  display: none;
}

.chatBox .chatBox-composerContainer .chatBox-composer input {
  border-radius: 1000px;
  transition: all 200ms;
  border: 1px solid rgba(2, 17, 69, 0.05);
  background-color: #F0F7FC;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  flex: 1;
}

.chatBox .chatBox-composerContainer .chatBox-composer input:focus {
  background-color: white;
  border: 1px solid rgba(2, 17, 69, 0.25);
}

.chatBox .chatBox-composerContainer .chatBox-sendBtn {
  border-radius: 1000px;
  background-color: #1782fc;
  height: 48px;
  width: 48px;
  color: white;
  font-family: 'Open Sans';
  font-size: 28px;
  padding: 0;
}

.chatCard {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  box-shadow: 12px 1px 0 0 rgba(2, 17, 69, 0.05);
}

.chatCard .name {
  font-weight: 500;
}

.chatCard.hasUnread .unreadIcon {
  opacity: 1;
  width: 8px;
  margin-left: 12px;
}

.chatCard .img {
  border-radius: 1000px;
  background-color: rgba(2, 17, 69, 0.05);
  height: 48px;
  width: 48px;
  margin-right: 12px;
}

.chatCard .time {
  color: rgba(2, 17, 69, 0.5);
}

.chatCard .txt {
  overflow-wrap: anywhere;
  flex: 1;
}

.chatCard .txt p,
    .chatCard .txt span {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* number of lines to show */
}

.chatCard .txt > p {
  margin-top: 4px;
  color: rgba(2, 17, 69, 0.5);
}

.chatCard .unreadIcon {
  transition: all 200ms;
  border-radius: 1000px;
  height: 8px;
  background-color: #11b5e4;
  opacity: 0;
}

.chatCard:hover {
  cursor: pointer;
  background-color: rgba(2, 17, 69, 0.05);
}

.searchBar {
  border-radius: 1000px;
  transition: all 200ms 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 20px;
  background-color: #ebf3fb;
  flex: 1;
}

.searchBar > input,
  .searchBar path {
  transition: all 200ms;
}

.searchBar > input {
  margin-left: 4px;
  width: calc(100% - 30px);
  background-color: transparent;
  outline: none;
  color: #203b54;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
}

.searchBar > input::-moz-placeholder {
  color: #97b6ce;
}

.searchBar > input:-ms-input-placeholder {
  color: #97b6ce;
}

.searchBar > input::-ms-input-placeholder {
  color: #97b6ce;
}

.searchBar > input::placeholder {
  color: #97b6ce;
}

.searchBar > input:focus + svg path {
  fill: #203b54;
}

.searchBar > svg path {
  fill: #97b6ce;
}

.searchBar.onSearchPage {
  width: 100%;
  margin-right: 0;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 500;
}

.post-search {
  width: 60vw;
}

@media (max-width: 767px) {
  .post-search {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .searchContainer {
    margin-right: 16px;
    margin-left: 16px;
  }
}

.searchContainer .searchLabel {
  font-size: 24px;
  margin-bottom: 16px;
}

.personHit {
  margin-left: 28px;
  margin-right: 28px;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 280px;
}

@media (max-width: 767px) {
  .personHit {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.bg-normal {
  background-color: #f9fcff;
}

.mHead {
  background-color: #f9fcff;
  z-index: 998;
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
}

@media (max-width: 767px) {
  .mHead {
    display: none;
  }
}

.mHead > header {
  max-width: calc(1080px - 298px - 16px);
  margin-left: 298px;
  margin-right: 16px;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 20px;
}

.pHead {
  width: 100%;
  height: 36px;
  margin-top: 84px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .pHead {
    margin-bottom: 16px;
    margin-top: 16px;
    padding-right: 16px;
  }
}

.pHead h1 {
  font-weight: 700;
  font-size: 36px;
  color: #3a5b79;
}

.pHead .pHead--nav {
  display: none;
}

@media (max-width: 767px) {
  .pHead .pHead--nav {
    display: block;
    margin-left: 12px;
  }
}

.accountDropdown .accountDropdown-displayArea,
.accountDropdown .dropMenu {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  transition: all 200ms;
}

.accountDropdown .accountDropdown-displayArea {
  background-color: transparent;
  border-radius: 1000px;
  height: 36px;
  width: 36px;
  box-shadow: 0px 2px 4px rgba(32, 59, 84, 0.2);
  box-sizing: content-box;
  overflow: hidden;
}

.accountDropdown .accountDropdown-displayArea:hover {
  cursor: pointer;
}

.accountDropdown .dropMenu {
  border-radius: 4px;
  position: absolute;
  margin-top: 12px;
  right: 0;
  z-index: 10;
  overflow: hidden;
  box-shadow: 0 4px 24px 0 rgba(35, 61, 77, 0.2);
  border: 1px solid rgba(35, 61, 77, 0.1);
  background-color: white;
  color: #97b6ce;
  min-width: 212px;
}

.accountDropdown .dropMenu .dropMenu-name {
  width: calc(100% - 24px);
  margin-left: 12px;
  margin-right: 12px;
  height: 42px;
  color: #3a5b79;
  font-weight: 600;
  margin-bottom: 4px;
  box-shadow: 0 1px 0 0 rgba(2, 17, 69, 0.1);
}

.accountDropdown .dropMenu .dropMenu-row {
  height: 42px;
  padding-left: 12px;
  padding-right: 12px;
}

.accountDropdown .dropMenu .dropMenu-row > div > div {
  margin-right: 8px;
}

.accountDropdown .dropMenu .dropMenu-row > img {
  transition: all 200ms;
  opacity: 0;
}

.accountDropdown .dropMenu .dropMenu-row > span {
  transition: all 200ms;
  margin-right: 8px;
  white-space: nowrap;
}

.accountDropdown .dropMenu .dropMenu-row:hover {
  cursor: pointer;
  color: #3a5b79;
}

.accountDropdown .dropMenu .dropMenu-row:hover > img {
  opacity: 1;
}

.calloutBox {
  border-radius: 4px;
}

.sortField--drop > div > button {
  color: #1782fc;
  font-weight: 600;
  text-transform: capitalize;
}

.editProfile .editProfile-title {
  margin: 0;
}

.editProfile .editProfile-title > h1 {
  font-size: 28px;
}

.editProfile .editProfile-title > span {
  color: rgba(2, 17, 69, 0.5);
}

.editProfile .editProfile-mainInfo {
  width: 100%;
}

.editProfile .editProfile-mainInfo .editProfile-name,
  .editProfile .editProfile-mainInfo .editProfile-bio {
  width: 100%;
}

.viewProfile {
  position: relative;
  width: 100%;
}

@media (max-width: 767px) {
  .viewProfile .viewProfile--userInfo {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
  }
}

.viewProfile .inner {
  width: 184px;
  margin-right: 36px;
}

@media (max-width: 767px) {
  .viewProfile .inner {
    margin-right: 0;
  }
}

.viewProfile .inner .img {
  border-radius: 1000px;
  background-color: transparent;
  box-sizing: content-box;
  overflow: hidden;
  border: 1px solid #97b6ce;
  box-shadow: 0 2px 24px 0 rgba(35, 61, 77, 0.15);
  height: 184px;
  width: 184px;
}

.viewProfile .inner button.chat {
  border-radius: 4px;
  height: 36px;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 16px;
  font-weight: 600;
  background-color: #203b54;
  color: white;
}

.viewProfile .inner .viewProfile-socials {
  flex-wrap: wrap;
}

.viewProfile .inner .viewProfile-socials > div {
  transition: all 200ms;
  border-radius: 100px;
  height: 36px;
  width: 36px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #e7f2fa;
}

.viewProfile .inner .viewProfile-socials > div > svg {
  height: 16px;
  width: 16px;
  fill: #6487a6;
}

.viewProfile .inner .viewProfile-socials > div:hover {
  cursor: pointer;
  background-color: #d3e3ed;
}

.viewProfile .inner .viewProfile-socials > div:hover > svg {
  fill: #3a5b79;
}

@media (max-width: 767px) {
  .viewProfile {
    padding-left: 16px;
    padding-right: 16px;
  }

  .viewProfile button.chat {
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .viewProfile .viewProfile-socials {
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
    justify-content: center;
  }
}

.viewProfile .viewProfile--right .top {
  color: #203b54;
}

.viewProfile .viewProfile--right .content {
  margin-top: 8px;
}

.viewProfile .viewProfile--right .content > div {
  margin-top: 28px;
  width: 100%;
}

.viewProfile .viewProfile--right .content > div > label {
  font-size: 14px;
  font-weight: 700;
  color: #203b54;
}

.viewProfile .viewProfile--right .content > div > p {
  font-size: 16px;
  color: #3a5b79;
}

@media (max-width: 767px) {
  .viewProfile .viewProfile--right {
    margin-top: 8px;
    min-height: 0;
    align-items: center;
  }
}

.viewProfile .viewProfile-postsContainer {
  margin-top: 88px;
  padding-bottom: 16px;
  width: 100%;
}

@media (max-width: 767px) {
  .viewProfile .viewProfile-postsContainer {
    margin-top: 36px;
  }
}

.viewProfile .viewProfile-postsContainer .divider {
  height: 2px;
  background-color: #d3e3ed;
}

.viewProfile .viewProfile-postsContainer .name {
  font-weight: 700;
  font-size: 28px;
  color: #203b54;
  margin-top: 48px;
  margin-bottom: 28px;
}

@media (max-width: 767px) {
  .viewProfile .viewProfile-postsContainer .name {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .viewProfile .viewProfile-postsContainer > div {
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
  }
}

.viewProfile .viewProfile--name {
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* number of lines to show */
}

.viewProfile .viewProfile--status {
  font-weight: 600;
  color: #97b6ce;
}

.admin .admin-topInfo a {
  transition: all 200ms;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  color: #021145;
  background-color: white;
  font-size: 14px;
  height: 36px;
  box-shadow: 0 2px 6px 0 rgba(2, 17, 69, 0.05);
  border: 1px solid rgba(2, 17, 69, 0.1);
}

.admin .admin-topInfo a > span {
  transition: all 200ms;
  width: 0;
  overflow: hidden;
  font-family: 'Open Sans';
  font-size: 12px;
  margin-left: 0;
}

.admin .admin-topInfo a:hover {
  cursor: pointer;
  box-shadow: 0 2px 6px 0 rgba(2, 17, 69, 0.1);
  background-color: rgba(2, 17, 69, 0.03);
}

.admin .admin-topInfo a:hover > span {
  width: 12px;
  margin-left: 4px;
}

.admin .admin-topInfo a:active {
  background-color: rgba(2, 17, 69, 0.05);
}

.admin .admin-nav {
  width: 100%;
  max-width: 100vw;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid rgba(2, 17, 69, 0.1);
  overflow-x: auto;
}

.admin .admin-nav .admin-nav-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  transition: all 200ms;
  border-radius: 4px;
  height: 28px;
  color: rgba(2, 17, 69, 0.5);
  padding-left: 8px;
  padding-right: 8px;
  background-color: rgba(2, 17, 69, 0.05);
  margin-right: 8px;
}

.admin .admin-nav .admin-nav-item.selected {
  pointer-events: none;
  background-color: #021145;
  color: white;
}

.admin .admin-nav .admin-nav-item:hover {
  cursor: pointer;
  background-color: rgba(2, 17, 69, 0.1);
}

.admin .admin-inner {
  width: 100%;
}

.admin .admin-inner .group {
  transition: all 200ms;
  border-radius: 4px;
  width: 100%;
  padding: 16px;
  border: 1px solid rgba(2, 17, 69, 0.05);
  background-color: rgba(2, 17, 69, 0.03);
}

.admin button {
  background-color: #021145;
}

tr:hover {
  background-color: 'red';
}

.xDropdown {
  transition: all 200ms;
  border-radius: 4px;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgba(2, 17, 69, 0.25);
  background-color: white;
  color: rgba(2, 17, 69, 0.6);
  text-transform: capitalize;
  font-weight: 600;
  max-height: 250px;
  overflow: auto;
}

.xDropdown > div {
  width: 100%;
}

.xDropdown > div > div {
  transition: all 200ms;
  height: 42px;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
}

.xDropdown > div > div:hover {
  cursor: pointer;
  color: #021145;
}

.feedNav {
  width: 100%;
  margin-right: 24px;
  position: relative;
  z-index: 9;
}

@media (max-width: 767px) {
  .feedNav {
    padding-left: 14px;
  }
}

.feedNav h1 {
  font-size: 28px;
  font-weight: 700;
  color: #203b54;
}

.feedNav.indexChannels h1 {
  font-size: min(2vw, 28px);
}

.feedNav-sort {
  font-weight: 600;
  color: #97b6ce;
}

.feedNav-sort .on_desktop {
  border-radius: 1000px;
  height: 36px;
  padding-left: 14px;
  padding-right: 8px;
}

@media (max-width: 767px) {
  .feedNav-sort {
    width: 100%;
    justify-content: space-between;
  }
}

.feedNav-mr {
  margin-right: 28px;
}

.feedNav.wide {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.channelSelector {
  color: #6487a6;
  font-weight: 600;
  height: 36px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 1000px;
  display: none;
}

@media (max-width: 767px) {
  .channelSelector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.pageLoading > div {
  transition: all 400ms;
  border-radius: 100px;
  width: 48px;
  height: 48px;
  border: 8px solid #B6F4FF;
  border-top-color: #18D9FE;
  border-right-color: #17AAFD;
  border-bottom-color: #1766FC;
  position: absolute;
  margin: 0 auto;
  animation: spin 1s ease-in-out alternate infinite;
  -webkit-animation: spin 1s ease-in-out alternate infinite;
  -moz-animation: spin 1s ease-in-out alternate infinite;
}

.directory {
  position: relative;
  width: 100%;
}

.directory .directory-list {
  width: 100%;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 767px) {
  .directory .directory-list {
    padding-left: 16px;
    padding-right: 16px;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
}

.directory .directory-entry {
  border-radius: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  transition: all 200ms;
  position: relative;
  background-color: white;
  overflow: hidden;
  border: 1px solid rgba(32, 59, 84, 0.15);
  box-shadow: 0 4px 12px 0 rgba(32, 59, 84, 0.05);
  box-sizing: border-box;
}

.directory .directory-entry:hover {
  cursor: pointer;
  border: 1px solid rgba(32, 59, 84, 0.35);
  box-shadow: 0 4px 16px 0 rgba(32, 59, 84, 0.1);
}

.directory .directory-entry:hover .top {
  box-shadow: 0 1px 0 0 rgba(32, 59, 84, 0.1);
}

.directory .directory-entry:hover .bottom > div {
  height: 16px;
  opacity: 1;
}

.directory .directory-entry .directory-photo {
  height: 100% !important;
  width: 100% !important;
  min-height: 48px;
  min-width: 48px;
  border-radius: 0;
}

.directory .directory-entry .top,
    .directory .directory-entry .bottom {
  width: 100%;
}

.directory .directory-entry .top {
  transition: all 200ms;
  overflow: hidden;
  position: relative;
  padding-top: 100%;
  box-shadow: 0 1px 0 0 rgba(32, 59, 84, 0.05);
}

.directory .directory-entry .top .top-in {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.directory .directory-entry .top .top-in > div {
  height: 100%;
  width: 100%;
}

.directory .directory-entry .top .top-in > div > div > div {
  font-size: 5vw !important;
}

@media (max-width: 767px) {
  .directory .directory-entry .top .top-in > div > div > div {
    font-size: 10vw !important;
  }
}

.directory .directory-entry .bottom {
  height: 80px;
  font-weight: 600;
}

.directory .directory-entry .bottom > span {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  margin-left: 12px;
  margin-right: 12px;
  text-align: center;
  width: calc(100% - 24px);
}

.directory .directory-entry .bottom > div {
  transition: all 200ms;
  height: 0;
  opacity: 0;
  font-size: 12px;
  font-weight: 600;
  color: #1782fc;
}

.directory .directory-entry .bottom > div > span {
  font-family: --apple-system;
}

@media (max-width: 767px) {
  .directory .directory-entry {
    width: 100%;
    margin: 0;
  }

  .directory .directory-entry .top {
    height: 188px;
  }

  .directory .directory-entry .bottom {
    height: 64px;
  }
}

.channelLockIcon {
  border-radius: 1000px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-color: #b6f4ff;
  color: #1782fc;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}

body {
  font-family: proxima-nova, -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 16px;
  font-weight: 400;
  color: #13253e;
  background-color: #f9fcff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

h3 {
  font-size: min(calc(0.5vw + 16px), 22px);
  font-weight: 600;
  line-height: 30px;
}

.a-highlight {
  height: 400px;
}

@media (max-width: 767px) {
  .a-highlight {
    height: 700px;
  }
}

.a-highlight a {
  color: #15c;
  text-decoration: underline;
}
